import { render } from "preact"
import { useEffect, useState } from "preact/hooks"

import { qs } from "./utils.js"

import { FactsAndFigures, WebsiteAndFollowerFacts } from "./facts.js"

const Arrow = () => (
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
    <path
      d="M23.115 24.885 16.245 18l6.87-6.885L21 9l-9 9 9 9 2.115-2.115Z"
      fill="#000"
    />
    <circle cx="18" cy="18" r="17" stroke="#000" stroke-width="2" />
  </svg>
)

function HTML({ plugin }) {
  return <div class="grid" dangerouslySetInnerHTML={{ __html: plugin.html }} />
}

function Quotation({ plugin }) {
  return (
    <div class="grid">
      <div class="quotation quotation--highlighted">
        <div
          class="quotation__quotation"
          dangerouslySetInnerHTML={{ __html: plugin.quotation }}
        />
        <img class="quotation__head" src={plugin.head} alt="" />
        <div class="quotation__name">{plugin.full_name}</div>
        <div class="quotation__function">{plugin.function}</div>
      </div>
    </div>
  )
}

function Unsupported({ plugin }) {
  return <strong>Unsupported plugin type {plugin.type}</strong>
}

const TYPES = {
  html: HTML,
  quotation: Quotation,
  factsandfigures: FactsAndFigures,
  websiteandfollowerfacts: WebsiteAndFollowerFacts,
}

function Region({ plugins }) {
  return (
    <>
      {plugins.map((plugin, index) => {
        const Type = TYPES[plugin.type] || Unsupported
        return <Type plugin={plugin} key={index} />
      })}
    </>
  )
}

function Regions({ initialize }) {
  const [data, setData] = useState(() => {
    return JSON.parse(qs("#regions-data").textContent)
  })
  const [menu] = useState(() => {
    return JSON.parse(qs("#annualreport-menu").textContent)
  })

  useEffect(() => {
    initialize()
  }, [data, initialize])

  const loadRegions = (e) => {
    e.preventDefault()
    const anchor = e.target.closest("a")
    fetch(anchor.href, { headers: { accept: "application/json" } })
      .then((r) => r.json())
      .then((data) => {
        window.history.replaceState(null, "", anchor.href)
        document.title = data.title
        document.body.style.setProperty("--accent", data.color)
        document.body.classList.toggle("body--black", data.inverted)
        setData(data.regions)
      })
  }

  const currentIndex = menu.findIndex((p) => p.url === location.pathname)
  const nextURL = currentIndex >= 1 ? menu[currentIndex - 1].url : null
  const previousURL =
    currentIndex > -1 && currentIndex < menu.length - 1
      ? menu[currentIndex + 1].url
      : null

  return (
    <>
      {data.map(([region, plugins]) => (
        <Region plugins={plugins} key={region} />
      ))}
      <div class="years">
        <a
          onClick={loadRegions}
          href={nextURL}
          class="years__arrow years__arrow--next"
          disabled={!nextURL}
        >
          <Arrow />
        </a>
        {menu.map((p) => (
          <a
            onClick={loadRegions}
            class={`years__year ${location.pathname === p.url ? "active" : ""}`}
            style={{ filter: "brightness(1.1)" }}
            href={p.url}
            key={p.url}
          >
            {p.title}
          </a>
        ))}
        <a
          onClick={loadRegions}
          href={previousURL}
          class="years__arrow years__arrow--previous"
          disabled={!previousURL}
        >
          <Arrow />
        </a>
      </div>
    </>
  )
}

export function renderRegions(el, initialize) {
  render(<Regions initialize={initialize} />, el.parentNode)
}
