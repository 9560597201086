import shuffle from "@anttikissa/fisher-yates-shuffle"
import { render } from "preact"
import { useState } from "preact/hooks"

function Partners() {
  const [showAll, setShowAll] = useState(false)
  const [allPartners] = useState(() => {
    const el = document.getElementById("initial-partners")
    return shuffle(JSON.parse(el.textContent))
  })

  const partners = showAll ? allPartners : allPartners.slice(0, 20)
  const hasMore = allPartners.length > 20

  return (
    <>
      <div class="partners__grid">
        {partners.map((partner, idx) => (
          <a
            href={partner.url}
            target="_blank"
            rel="noopener noreferrer"
            class="partner"
            key={idx}
          >
            <img src={partner.logo} alt={partner.name} loading="lazy" />
          </a>
        ))}
      </div>
      {hasMore && !showAll ? (
        <a
          class="show-all"
          href="#"
          onClick={(e) => {
            e.preventDefault()
            setShowAll(true)
          }}
        >
          Show all partners
        </a>
      ) : null}
    </>
  )
}

export function renderPartners(el) {
  el && render(<Partners />, el)
}
